











































/deep/ .slide-verify-slider-mask-item {
  width: 38px !important;
  height: 38px !important;
}
