#registeredAccount {
  height: 100%;
  overflow-y: auto;
  background: #f8fafd;
}
#registeredAccount .container {
  flex: 1;
  width: 100%;
  padding: 60px 0 90px;
  background: #F9FAFC;
}
#registeredAccount .container .loginControlForget {
  width: 500px;
  margin: 0 auto;
}
#registeredAccount .container .loginControlForget .loginTitle {
  text-align: center;
  padding: 30px 0 20px;
  font-size: 26px;
}
#registeredAccount .container .loginControlForget .loginInput .roleKey {
  display: block;
  text-align: center;
  margin-bottom: 20px;
}
#registeredAccount .container .loginControlForget .loginInput .ADMIN_TRAIN_ROLE_Data_title {
  font-size: 18px;
}
#registeredAccount .container .loginControlForget .loginInput .ADMIN_TRAIN_ROLE_Data_title span {
  font-size: 14px;
  margin-left: 20px;
}
#registeredAccount .container .loginControlForget .loginInput /deep/ .el-upload {
  margin-top: 20px;
  width: 5rem;
  height: 28px !important;
  border: none !important;
}
#registeredAccount .container .loginControlForget .loginInput /deep/ .el-upload .el-button {
  padding: 0 14px !important;
}
#registeredAccount .container .loginControlForget .loginInput .flieListDta {
  line-height: 24px;
  font-size: 12px;
}
#registeredAccount .container .loginControlForget .loginInput .flieListDta .flieListDta_1 {
  width: 340px;
}
#registeredAccount .container .loginControlForget .loginInput .flieListDta .flieListDta_2 {
  width: 30px;
  text-align: right;
  margin-left: 10px;
  color: #2878FF;
  cursor: pointer;
}
#registeredAccount .container .loginControlForget .loginInput .flieListDta .flieListDta_3 {
  width: 30px;
  text-align: right;
  margin-left: 10px;
  color: #f46173;
  cursor: pointer;
}
#registeredAccount .container .loginControlForget .loginButtonForget {
  padding: 1.25rem 0 0;
  display: flex;
  text-decoration: none;
}
#registeredAccount .container .loginControlForget .loginButtonForget > .el-button {
  width: 100%;
  height: 2.5rem;
}
#registeredAccount .container .loginControlForget .loginButtonForget .bgc-bv {
  background: #2878FF !important;
  border-color: #2878FF !important;
}
#registeredAccount .container .loginControlForget .loginButtonForget .bgc-bv:hover {
  background: #2878FF !important;
  border-color: #2878FF !important;
}
#registeredAccount .container .elForm {
  box-shadow: 0px 6px 50px 1px #eeeeee;
  padding: 40px 40px 10px 40px;
  border-radius: 6px;
}
#registeredAccount .container .elForm .yzmz {
  position: absolute;
  top: 0;
  right: 20px;
  color: #2878ff;
  font-size: 12px;
  cursor: pointer;
}
 /deep/ .slide-verify-slider-mask-item {
  width: 38px !important;
  height: 38px !important;
}
 /deep/ .el-dialog__header {
  padding: 5px 0;
}
.registrationCompletedDialog {
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
  position: fixed;
  top: 0;
  left: 0;
}
.registrationCompletedDialog .shell {
  width: 500px;
  height: 250px;
  background: #fff;
  border-radius: 6px;
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -250px;
  margin-top: -150px;
}
.registrationCompletedDialog .shell .p1 {
  font-size: 26px;
  text-align: center;
  margin-top: 50px;
}
.registrationCompletedDialog .shell .p2 {
  text-align: center;
  margin-top: 40px;
}
.registrationCompletedDialog .shell button {
  display: block;
  margin: auto;
  width: 60%;
  margin-top: 20px;
}
 /deep/ .el-radio .el-radio__input.is-checked .el-radio__inner {
  border-color: #2878ff !important;
  background-color: #2878ff !important;
}
